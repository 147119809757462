<template>
  <v-row class="lasa-questionnaire-panel reported_questionnaires">
    <v-col cols="12" class="sub-title ml-2">
      {{ `${$t("reported")}  ${$t("questionnaires")}` }}
    </v-col>
    
    <v-col cols="12">
      <DasCard
        title="DAS-28"
        date="03/06/2021"
        :data="DasData"
        :joints="DasData"
        editBtn
      />
    </v-col>

    <v-col cols="12">
      <v-expansion-panels class="pr-1" v-model="openPanel">
        <v-expansion-panel
          v-for="(questionnaire, i) in questionnaires"
          :key="i"
        >
          <v-expansion-panel-header
            class="pa-6"
            :expand-icon="openPanel == i ? 'mdi-minus' : 'mdi-plus'"
          >
            <span class="d-flex-inline pt-2 pb-2 date">
              {{ questionnaire.date }}
            </span>
            <span class="float-right flex-2 score" v-if="openPanel != i">
              {{ $t("score") }} <strong>{{ questionnaire.score }}</strong>
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content> </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import DasCard from "@/components/views/dashboard/members/id/partial/cards/DasCard";

export default {
  name: "LASAQuestionnairePanels",

  props: {
    title: {
      type: String,
      default: "",
    },
    questionnaires: {
      type: Array,
      default: () => [],
    },
    questions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      openPanel: null,
      DasData: {
        right_hand_1: true,
        right_hand_2: false,
        right_hand_3: false,
        right_hand_4: true,
        right_hand_5: false,
        right_hand_6: false,
        right_hand_7: false,
        right_hand_8: true,
        right_hand_9: false,
        right_hand_10: false,
        left_hand_1: false,
        left_hand_2: false,
        left_hand_3: false,
        left_hand_4: true,
        left_hand_5: false,
        left_hand_6: false,
        left_hand_7: false,
        left_hand_8: false,
        left_hand_9: true,
        left_hand_10: false,
        upper_body_1: false,
        upper_body_2: false,
        upper_body_3: true,
        upper_body_4: false,
        upper_body_5: true,
        upper_body_6: false,
        lower_body_1: true,
        lower_body_2: false,
      },
    };
  },

  components: {
    DasCard,
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/questionnaires/components/lasa-questionnaire-panels.scss">
</style>